<template lang="pug">
    div.pa-10
        PageTitle(:label="id ? `Editar Pedido - #${id}` : 'Novo Pedido'")
            v-btn( color="success" v-if="id" style="float: right" :href="`${api.url}/order/${id}/print`" target="_blank") Imprimir
                v-icon(right) mdi-printer
        
        div(v-acl.cant.hide='id ? `order.edit` : `order.add`') Você não possui a permissão necessária para acessar o recurso
        div(v-acl.can.hide='id ? `order.edit` : `order.add`')
            v-form(v-model="valid")
                
                form-client(v-model="client" ref="formClient")

                v-container( fluid )
                    h3.mb-10.mt-5 Produtos
                    <v-simple-table v-if="items.length > 0">
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Produto</th>
                                <th class="text-center" width="100">Quantidade</th>
                                <th class="text-center" width="100">Preço</th>
                                <th class="text-center" width="100">Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, key) in items" :key="item.name" >
                                <td>{{ item.product }}</td>
                                <td class="text-center">{{ item.qty }}</td>
                                <td class="text-center">{{ item.price | money }}</td>
                                <td class="text-center"><strong>{{ item.total | money }}</strong></td>
                                <td class="text-center">
                                    v-btn( color="primary" small @click="removeItem(key)")
                                        v-icon mdi-delete
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="text-right">Subtotal:</td>
                                <td class="text-center"> {{ subtotalOrder | money }}</td>
                            </tr>
                            <tr v-if="shipment > 0">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="text-right">Frete:</td>
                                <td class="text-center"> {{ shipment | money }}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="text-right">Total:</td>
                                <td class="text-center"> {{ totalOrder | money }}</td>
                            </tr>
                        </tfoot>
                        </template>
                    </v-simple-table>


                    v-row(align="center" justify="center")
                        v-col(cols="12" md)                        
                            v-combobox(
                                v-model="temp.item.selected"
                                :items="productsFormatted"
                                :loading="search.products.loading"
                                :search-input.sync="temp.item.product"
                                hide-no-data
                                hide-selected
                                item-text="combined"
                                item-value="combined"
                                label="Produto"
                                outlined
                                hide-details
                                ref="item_product"
                            )                           


                        v-col(cols="3" md="2" lg="1")
                            <v-text-field outlined type="number" step="0.1" v-model.number="temp.item.qty" label="Quantidade" hide-details required></v-text-field>
                        
                        v-col(cols="3" md="2" lg="2")
                            v-text-field-money(
                                v-model="temp.item.price"
                                label="Preço"
                                v-bind:properties="{ prefix: 'R$', readonly: false, required: true, outlined: true, clearable: true, placeholder: ' ', 'hide-details': true}"
                            )
                            
                        
                        v-col(cols="3" md="2" lg="2")
                            v-text-field( outlined :value="totalItem | money" readonly disabled hide-details)

                        v-col(cols="3" md="2" lg="1")
                            v-btn( color="success" x-large @click="addItem")
                                v-icon mdi-plus

                v-container( fluid )
                    h3.mb-10.mt-5 Pagamento
                    .row
                        div( :class="{ 'col-md-6' : (payment != 2 && payment != 3 ), 'col-md-4' : (payment === 2 ||  payment === 3) }")
                            v-text-field-money(
                                v-model="shipment"
                                label="Entrega"
                                v-bind:properties="{ prefix: 'R$', readonly: false, outlined: true, clearable: true, placeholder: ' ',}"
                            )

                        div( :class="{ 'col-md-6' : (payment != 2 && payment != 3 ), 'col-md-4' : (payment === 2  || payment === 3) }")
                            v-select( :items="config.PAYMENT_METHODS" item-text="label" item-value="type" v-model="payment" label="Forma de Pagamento" outlined)

                        .col-md-2(v-if="payment === 2 || payment === 3")
                            v-text-field-money(
                                v-model="change_for"
                                label="Troco para"
                                v-bind:properties="{ prefix: 'R$', readonly: false, outlined: true, clearable: true, placeholder: ' ',}"
                                )
                        
                        .col-md-2(v-if="payment === 2 || payment === 3")
                            v-text-field( :value="( parseFloat(change_for) > parseFloat(totalOrder)  ) ? (parseFloat(change_for) - parseFloat(totalOrder) ) : 0 | money" readonly disabled label="Troco" outlined)
                
                v-btn( color="success" x-large @click="sendOrder" :disabled="onSave" :loading="onSave") Salvar
                                v-icon(right) mdi-content-save

                    
        
</template>

<script>

export default {
  name: "OrderForm",
  components: {
      FormClient: () => import( '@/views/Clients/components/FormClient' ),
      'v-text-field-money' : () => import( '@/components/form/InputMoney' ),
      PageTitle: () => import( '@/components/PageTitle' ),
  },
  data: function(){
      return {
          onSave: false,
          valid: false,
          id: null,
          //cities: [],

          client: {},// client
          items: [],
          payment: null,
          change_for: 0.00,
          shipment: 0.00,

          temp: {
              item : {
                  selected: null,
                  product: null,
                  qty: null,
                  price: 0.00
              },
          },




          search: {
              products: {
                  loading: false,
                  word: null,
                  result: []
              },
          },

          

          isLoadingProducts: false,
          autoCompleteProducts: [],

      }
  },

  computed: {
      productsFormatted: function(){
          return this.search.products.result.map((item) => {
              let combined = `${item.sku} - ${item.label}`
              return Object.assign({}, item, { combined } )
          })
      },

      totalItem: function(){
          return this.temp.item.price * this.temp.item.qty
      },

      subtotalOrder: function(){
          return this.items.reduce(( a, b) => {
              return parseFloat(a) + parseFloat( b.total )
          },0)
      },

      totalOrder: function(){
          return this.items.reduce(( a, b) => {
              return parseFloat(a) + parseFloat( b.total )
          },0) + parseFloat( this.shipment )
      },
  },

    mounted: function(){
        if( this.$route.params.id ){
            this.id = this.$route.params.id
            this.loadData()
        }
    },

    watch: {
        'temp.item.product' : function( search ) {
            let self = this
            
            if( search === null ) return

            search = search.toUpperCase()
            self.temp.item.product = search;

            if( self.search.products.loading ) return

            this.$nextTick(function(){

                if( search.length >= 3){
                    self.search.products.loading = true
                    
                    this.api.post( 'products/search', { s: search } ).then(function( response ){
                        self.search.products.result = response.data
                    }).finally(function(){
                        self.search.products.loading = false;
                    })
                }
            })

        },

        'temp.item.selected' : function( value ){
            if( value !== null && typeof(value) == 'object' ){
                this.temp.item.price = value.price
            }
        },
    },

    methods:{
        loadData: async function(){
            let response = await this.api.get( `order/${this.$route.params.id}` )
            let order = response.data
            this.$refs.formClient.getById( order.client_id )
            this.items = order.items
            this.change = order.change
            this.change_for = order.change_for
            this.shipment = order.shipment
            this.payment = order.payment_method
            this.total = order.total
        },
        
        addItem: function(){

            if( !this.temp.item.product ){
                this.$root.toast.show( {message: 'Informe o nome do produto', color: 'warning', icon: 'mdi-alert-outline' })
                return;
            }
            if( !this.temp.item.qty){
                this.$root.toast.show( {message: 'Informe a quantidade', color: 'warning', icon: 'mdi-alert-outline' })
                return;
            }
            if( !this.temp.item.price ){
                this.$root.toast.show( {message: 'Informe o valor', color: 'warning', icon: 'mdi-alert-outline' })
                return;
            }


            this.items.push({
                product: this.temp.item.product,
                qty: parseFloat( this.temp.item.qty ).toFixed(2),
                price: parseFloat( this.temp.item.price ).toFixed(2),
                total: parseFloat( this.temp.item.price * this.temp.item.qty ).toFixed(2),
            })

            // reset temp item

            this.temp = {
                item : {
                    selected: null,
                    product: null,
                    qty: null,
                    price: 0.00
                }
            }
            this.$refs.item_product.focus()
        },

        removeItem: function( key ){
            this.items.splice(key, 1)
        },


        sendOrder: function(){

            let self = this;
            
            let order = {
                client: this.client,
                items: this.items,
                shipment: this.shipment,
                change: ( this.change_for > this.totalOrder  ) ? ( this.change_for - this.totalOrder ) : 0,
                change_for: this.change_for,
                payment_method: this.payment,
            }

            this.onSave = true;


            if( !this.id ){
                // New
                this.api.post( 'order', order ).then(function( response ){
                    self.onSave = false;
                    if( response ){
                        self.$root.toast.show( {message: "Pedido criado com sucesso!" } )
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                        setTimeout(function(){
                            self.$router.push( { name: 'orders-edit', params: { id: response.data.id } })
                        }, 1000)
                        
                        
                    }
                        
                })
            }else{
                // update

                this.api.put( `order/${this.$route.params.id}`, order ).then(function( response ){
                    self.onSave = false;
                    if( response )
                        self.$root.toast.show( {message: "Pedido editado com sucesso!" } )
                })
            }
        },
        
        
        
    },
};
</script>
